enum ROLES {
  SUPER_ADMIN = "superAdmin",
  ADMIN = "admin",
  EDITOR = 'editor',
  CONTRIBUTOR = 'contributor',
  USER = 'user',
}

export const BLOCKED_ROLES = [ROLES.USER];

export default ROLES;
